@import "mixins";
@import "variables";

// Product image

.product-image {
	@include defaultMargin();
}

// Product configurator

.product-configurator {
	&__title {
		font-weight: $bold; margin: 20px 0 0 0;
	}
	&__group {
		display: flex; flex-direction: row; flex-wrap: nowrap; margin: 5px -5px 0 0;
		> LI { margin: 5px 5px 0 0; }
	}

	LABEL {
		display: flex; flex-direction: row; flex-wrap: nowrap; overflow: hidden; cursor: pointer; font-size: 14px; line-height: 16px;
		INPUT {
			position: absolute; right: 100%; bottom: 100%;
			&:checked + .label { box-shadow: inset 0 0 0 2px $color-link; }
			&:checked + .swatch { box-shadow: 0 0 0 2px $color-white, 0 0 0 4px $color-link; }
		}
		.label {
			flex: 1 1 auto; display: block; overflow: hidden; padding: 10px 15px; box-shadow: inset 0 0 0 1px $color-gray-light; border-radius: $base-radius;
			&:hover { box-shadow: inset 0 0 0 1px $color-gray-medium; }
		}
		.swatch {
			margin: 4px; display: block; width: 24px; height: 24px; overflow: hidden; border-radius: 50%; box-shadow: 0 0 0 3px $color-white, 0 0 0 4px rgba($color-black, 0.07); position: relative;
			&:hover { box-shadow: 0 0 0 3px $color-white, 0 0 0 4px rgba($color-black, 0.15); }
			&:before { content: ""; position: absolute; left: 0; right: 0; top: 0; bottom: 0; background: linear-gradient(135deg, rgba($color-black, 0), rgba($color-black, 0.03)); }
		}
	}
}

// Product pricing

.product-pricing {
	background-color: $color-gray-lighter; @include defaultMargin(); padding: $gridunit / 2 $gridunit $gridunit $gridunit; border-radius: $base-radius;
	&.secondary { margin-top: $gridunit / 2; }
	&.type02 {
		padding:  $gridunit/2 $gridunit-large $gridunit; border-radius: $base-radius-medium;
	}
	&__options {
		@include not-mobile {
			display: flex; flex-wrap: wrap; gap: $gridunit;
			&.vertical {
				display: block;
				> LI {
					INPUT[type="radio"] + .product-pricing__option {
						padding-top: $gridunit; padding-left: $gridunit*2+$gridunit*1.25;
						.columns {
							display: flex;
							.column { flex: 1; }
						}
					}
				}
			}
		}
		@include mobile {
			&.vertical {
				> LI {
					INPUT[type="radio"] + .product-pricing__option {
						.columns {
							.column + .column { margin-top: $gridunit-small; }
						}
					}
				}
			}
		}
		margin: 0 $gridunit/-2;
		+ .product-pricing__button { margin-top: $gridunit; }
		> LI {
			display: flex; margin: $gridunit/2 0 0; flex: 1 0 auto;
			@include desktop {
				max-width: calc(50% - #{$gridunit/2});
				&:first-child:last-child { max-width: 100%; }
			}
			&.primary {
				flex: 0 0 100%; min-width: 55%; max-width: none;
				+ *:last-child { max-width: none; }
			}
			INPUT[type="radio"] {
				position: absolute; left: -10000px;
				&:checked + .product-pricing__option {
					background-color: $color-white;
					&:before { border-color: $color02; }
					&:after { background-color: $color02;}
				}
				&:disabled + .product-pricing__option {
					box-shadow: none;
					&:before,
					&:after { opacity: 0.5;}
					&:before { background-color: $color-gray-lightest02; }
					LABEL:before { display: none; }
				}
				+ .product-pricing__option {
					padding-top: $gridunit*2+$gridunit*1.25;
					&:before,
					&:after {
						content: ""; position: absolute; left: $gridunit-large; top: $gridunit
					}
					&:before { width: 24px; height: 24px; border: 1px solid $color-gray-medium-light; box-sizing: border-box; border-radius: 50%; }
					&:after { width: 12px; height: 12px; margin: 6px; border-radius: 50%; }
				}
			}
		}
	}
	&__option {
		background-color: $color-gray-lightest; border-radius: $base-radius-large; padding: $gridunit $gridunit-large; position: relative; flex: 0 0 100%; box-sizing: border-box; font-size: $font-size-smaller;
		box-shadow: 0 4px 20px rgba(0, 0, 0, 0.13);
		.control-select { z-index: 2; }

		LABEL {
			&:before { content: ""; position: absolute; left: 0; top: 0; right: 0; bottom: 0; cursor: pointer; z-index: 1; }
			SPAN {
				display: block; word-wrap: break-word;
				&.label { font-size: $font-size-smaller; }
				&.price {
					white-space: nowrap; font-weight: $bold; font-size: $font-size-large02;
					DEL { opacity: 0.7; font-weight: $light; }
					&.avg { white-space: normal; font-size: $font-size-smaller; opacity: 0.5; font-weight: normal; }
				}
			}
		}
		P {
			&.size-large { font-size: $font-size-medium; font-weight: $bold; line-height: 1.4; margin: 0; }
		}
		UL:not([class]) LI {
			padding-left: $gridunit; margin: 0;
			&:before { background-color: $color05; left: 0; width: 7px; height: 7px; }
		}
		A {
			position: relative; z-index: 2; color: $color05;
			&.has-text-color-primary { color: $color02; }
		}
	}
	&__prices {
		display: flex; flex-direction: row; flex-wrap: wrap; align-items: center; padding: 0; margin-right: $gridunit * -1; position: relative;
		> LI {
			padding: $gridunit / 2 $gridunit 0 0; box-sizing: border-box; flex: 1 1 50%; overflow: hidden;

			.title { font-size: 18px; line-height: 24px; font-weight: $bold; }
			.label { font-size: 12px; line-height: 16px; }
			.price {
				font-size: 24px; line-height: 24px; font-weight: 300;
				&.old { font-weight: 300 !important; opacity: 0.5; text-decoration: line-through; margin-top: 5px; }
				&.avg { font-weight: 400 !important; opacity: 0.5; font-size: $font-size-small; }
				&.sale { color: $color08; }
			}

			&.primary {
				.label {}
				.price { font-weight: $bold; }
			}
			&.actions {
				display: block;
				flex: 1 0 250px
			}
		}
		& + & {
			margin-top: ($gridunit / 2) + 1;
			&:before { content: ""; position: absolute; left: 0; right: $gridunit; top: 0; height: 1px; background-color: $color-gray-light; }
		}
	}

	&__button {
		font-weight: 400; font-size: 18px; background-color: $color-link; border: none; outline: none; color: $color-white; padding: 0 50px; text-align: center; line-height: 40px; cursor: pointer; border-radius: $base-radius; margin: 0; display: block; width: 100%; box-sizing: border-box; white-space: nowrap;
		&:hover { background-color: $color-link-hover; text-decoration: none; }

		&.secondary { background-color: $color05; }

		&[disabled],
		&.disabled { opacity: 0.5; pointer-events: none; }
	}
	&__button + &__button { margin-top: $gridunit / 4; }
}

// Nuggets

.product-nuggets {
	display: flex; flex-direction: row; flex-wrap: wrap; margin: 0 -20px 0 0;
	> LI { flex: 1 1 50%; max-width: 50%; box-sizing: border-box; padding: 20px 20px 0 0; }
}

// Availability

.product-availability {
	padding: 0; margin: 8px 0 0 0;
	> LI {
		display: block; margin: 2px 0 0 0; padding: 0 0 0 20px; position: relative; z-index: 0;
		&:before { content: ""; position: absolute; left: 0; top: 4px; width: 10px; height: 10px; background-color: $color-gray-lightest; border-radius: 50%; }
		&.available {
			&:before { background-color: $color-success; }
			.status { color: $color-success; }
		}
		&.unavailable {
			&:before { background-color: $color-danger; }
			.status { color: $color-danger; }
		}
		&.preorder {
			&:before { background-color: $color-white; border: 2px solid $color-gray-medium; box-sizing: border-box; }
			.status { color: $color-gray-medium; }
		}
		&.warning {
			&:before { background-color: $color-warning; }
			.status { color: $color-warning; }
		}
	}
	A {
		color: inherit;
		&:hover { text-decoration: none; color: $color-link; }
	}
}

// Financing calculator

.financing-calculator {
	border: 1px solid $color-gray-light; margin-top: 10px; padding: 0 0 $gridunit $gridunit; border-radius: $base-radius;
	display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between;

	&__form {
		flex: 1 0 230px; padding: 15px $gridunit 0 0;
		&__row {
			$h: 30px;
			display: flex; flex-direction: row; flex-wrap: nowrap; margin: 5px 0 0 0;
			LABEL { line-height: $gridunit; padding: 5px 20px 5px 0; flex: 0 0 100px; box-sizing: border-box; font-size: 12px; }
			INPUT, SELECT {
				border: 0 none; box-shadow: none; font-size: inherit; appearance: none; outline: none;
				margin: 0; width: 100%; background-color: $color-gray-lightest; height: $h; line-height: $h; border-radius: $base-radius;
				&:focus { box-shadow: inset 0 0 0 1px rgba($color-black, 0.1) }
			}
			INPUT {
				padding: 0 10px;
				&:not(:last-child) { padding-right: 50px; }
			}
			SELECT {
				padding: 0 30px 0 10px; background: $color-gray-lightest url('img/select-arrow.png') 100% 50% no-repeat; background-size: auto 16px;
				&::-ms-expand {
					display: none;
				}
			}
			.fields { flex: 1 1 auto; display: flex; flex-direction: row; flex-wrap: nowrap; overflow: hidden; box-shadow: inset; position: relative; }
			.addon { position: absolute; right: 0; top: 0; padding: 0 10px; line-height: $h; pointer-events: none; font-size: 12px; opacity: 0.6; }

			@include mobile {
				display: block;
				LABEL { padding: 0 0 5px 0; }
			}
		}
	}
	&__result {
		flex: 0 0 200px; padding: $gridunit $gridunit 0 0;
		.result-label {}
		.result-sum { font-size: 24px; line-height: 30px; font-weight: $bold; }
		.result-disclaimer { font-size: 11px; line-height: 1.5; color: $color-gray-dark; margin: 10px 0 0 0; max-width: 200px; }
	}
}

// Discover other products

.product-discover {
	background-color: $color-gray-lightest; overflow: hidden; flex: 0 0 auto; box-sizing: border-box;
	&__categories {
		> LI { width: 100px; text-align: center; font-size: 11px; line-height: 14px; box-sizing: border-box; padding: 0 10px; display: inline-block; vertical-align: top; }
		A {
			color: $color-gray-dark; display: block; white-space: normal;
			.image { display: block; padding: 55% 0 0 0; background-size: contain; background-repeat: no-repeat; background-position: 50% 50%; margin-bottom: 5px; }
			.label { display: block; }
			.cat-label { display: block; margin: 0; vertical-align: 0; }
			&:hover { text-decoration: none; color: $color-link; }
		}
	}
	@include mobile {
		margin: $gridunit $gridunit * -1 0 $gridunit * -1;
		&__categories {
			flex: 0 0 auto; margin: 0; box-sizing: border-box; text-align: center;
			overflow: hidden; overflow-x: auto; -ms-overflow-style: auto; -webkit-overflow-scrolling: touch; scroll-behavior: auto; padding: 15px 0; white-space: nowrap;
		}
	}
	@include not-mobile {
		margin: $gridunit 0 0 0; padding: 20px; text-align: center; border-radius: $base-radius;
	}
}

// Sale marker

.product-sale {
	position: absolute; right: 0; top: 0; z-index: 2;
	background-color: $color08; color: $color-white; font-weight: 300; text-align: center;
	line-height: $gridunit; min-width: $gridunit; border-radius: $gridunit; padding: $gridunit / 2;  box-sizing: content-box;
}

// Product tags

.product-tags {
	@include mobile {
		.layout-browse & {
			order: -1;
		}
	}
}
