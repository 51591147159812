@import "../../../scss/variables";
@import "../../../scss/mixins";

.layout-sidebar {
	&, &.block-limiter { padding: 0 !important; }
	@include not-desktop {
		&-secondary {
			display: none;
		}
		&-button {
			display: block;
		}
		@at-root {
			.frame-checkout { padding-top: $mobile-header-height * 2; }
		}
	}
	@include desktop {
		position: relative;
		&, &.block-limiter { flex: 1 0 auto; display: flex; flex-direction: row; flex-wrap: nowrap; }
		&-primary {
			flex: 0 1 100%;
		}
		&-secondary {
			flex: 0 0 300px; margin-left: $gridunit; position: relative;
			.sticky { position: sticky; top: 0; }
		}
		&-button { display: none; }
	}

	&-button {
		position: fixed; z-index: 2; left: 0; right: 0; top: $mobile-header-height; height: $mobile-header-height; background-color: $color-gray-lightest; overflow: hidden;
		box-shadow: 0 5px 5px 0 rgba($color-black, 0.1);
		BUTTON { display: block; padding: 0; margin: 0; box-shadow: none; width: 100%; height: 100%; border: none; background-color: transparent; color: inherit; text-align: left; }
		.inner {
			height: 100%; box-sizing: border-box; @include defaultPadding; padding-top: $gridunit / 2 !important; padding-bottom: $gridunit / 2 !important; cursor: pointer;
			display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center;
			svg { width: 30px; height: 30px; flex: 0 0 auto; margin-right: $gridunit / 2; }
		}
		.text { flex: 1 1 auto; overflow: hidden; text-overflow: ellipsis; }
		.addon { flex: 0 0 auto; font-weight: 600; }
	}
}
