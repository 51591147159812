@import "~@blueprintjs/core/src/common/variables";
@import "~@blueprintjs/core/src/common/mixins";
@import "~@blueprintjs/core/src/common/react-transition";
@import "~@blueprintjs/core/src/components/popover/common";

@import "mixins";
@import "variables";

.frame-valgeklaar {
	flex: 1 0 auto; box-sizing: border-box; min-height: 100vh; min-height: -webkit-fill-available;
	display: flex; flex-direction: column;

	.userhub {
		display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; margin: -15px 0; line-height: $gridunit;

		LI { margin-left: 20px; flex: 0 0 auto; }
		.#{$ns}-popover-wrapper,
		.#{$ns}-popover-target { display: block; position: relative; }
		A {
			color: inherit; text-decoration: none; display: flex; align-items: center; justify-content: center; position: relative; padding: 15px 0;
			.icon {
				flex: 0 0 auto; display: block; width: 14px; height: 14px; overflow: hidden; margin: -3px 7px -3px 0; opacity: 0.5;
				img { width: 100%; height: auto; float: left; }
				@include mobile { margin: 0; }
			}
			.amount { margin-left: 7px; color: $color-link; font-weight: 400; }
			.products { display: none; }
			.#{$ns}-popover-target { display: block; position: relative; }
			&:hover {
				.icon { opacity: 1; }
			}

			&.desktop {
				position: absolute; left: 0; right: 0; top: 0; bottom: 0; z-index: 2;
				@include not-desktop { display: none; }
				&:hover + * {
					color: $color-text-dark;
					.icon { opacity: 1; }
				}
			}
		}

		UL { display: none; }

		@include mobile {
			.label, .amount { display: none; }
			LI { margin: 0; }
			A {
				padding: 10px; position: relative;
				.products {
					position: absolute; right: 0; top: 0;
					background-color: $color-link; color: $color-white;
					font-size: 9px; line-height: 16px; padding: 0 3px; min-width: 16px; text-align: center; border-radius: 8px; box-sizing: border-box;
					display: flex; align-items: center; justify-content: center;
				}
			}
		}
		@include not-mobile {
			.link-search { display: none; }
		}
	}

	&__pre-header {
		display: flex; flex-direction: row; flex-wrap: nowrap; flex: 0 0 auto; box-sizing: border-box; background-color: $color-black; color: $color-white;
		padding: 10px $spacer-desktop; font-size: 12px;

		&__limiter {
			width: 100%; max-width: $content-maxwidth; margin: auto; box-sizing: border-box;
			display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; justify-content: space-between;
		}

		&__languages {
			display: flex; flex-direction: row; flex-wrap: nowrap;
			> A { margin: 0; padding: 5px; display: flex; align-items: center; justify-content: center; }
			IMG { width: 20px; height: auto; margin: auto 0; }
		}

		.search__results {
			position: absolute; left: 0; right: 0; top: 100%; z-index: 100; margin-top: 10px;
			color: $color-text-dark; transition: all $uispeed $uieasing 0ms; opacity: 0;
			background-color: rgba($color-gray-lightest, 0.9); border: solid $color-gray-light; border-width: 0 1px 1px 1px; border-radius: 0; font-size: 13px;
			max-height: 350px; overflow: hidden; display: flex; flex-direction: column;

			&.open {
				opacity: 1;
				& .search__results__list { transform: translate(0, 0); }
			}
		}

		@include mobile {
			padding: 10px $spacer-mobile;
			.search { display: none; }
		}
		@include tablet {
			padding: 10px $spacer-mobile;
		}
		@include not-desktop {
			.userhub { display: none; }
		}
		@include desktop {
		}
	}

	&__header {
		display: flex; flex-direction: row; flex-wrap: nowrap; flex: 0 0 auto; box-sizing: border-box; position: sticky; z-index: 10; top: 0; background-color: rgba($color-white, 0.90); border-bottom: 1px solid $color-gray-light;
		padding: 0 $spacer-desktop; backdrop-filter: saturate(180%) blur(20px);

		&__limiter {
			width: 100%; max-width: $content-maxwidth; margin: auto; box-sizing: border-box;
			@include not-mobile { display: flex; flex-direction: row; flex-wrap: nowrap; align-items: stretch; justify-content: space-between; }
			@include mobile {
				display: flex; flex-direction: row; align-items: stretch; justify-content: space-between;
			}
		}

		.logo {
			padding: 30px 20px 30px 0; display: flex; align-items: center; justify-content: center;
			img { width: 150px; height: auto; display: block; }

			@include not-desktop { padding: 0; margin-right: auto; }
			@include mobile {
				padding: 5px;
				IMG { width: auto; height: 30px; }
			}
			@media only screen and (max-width: 360px) {
				IMG { height: 20px; }
			}
		}

		.userhub {
			A {
				.icon {
					float: left; width: 20px; height: 20px;
					IMG { transform: translate(0, -50%); }
				}
			}
		}

		@include not-desktop {
			padding: $spacer-mobile;
			.site-menu { order: 1; }
			.logo { order: 2; }
			.userhub { order: 3; }
		}
		@include mobile {
			padding: 5px 10px;
		}
		@include desktop {
			.userhub { display: none; }
		}
	}

	&__submenu {
		background-color: $color-gray-lightest; width: 100%; overflow: hidden; flex: 0 0 auto; border-bottom: 1px solid $color-gray-lighter;
		&__limiter {
			width: 100%; max-width: $content-maxwidth; margin: auto; box-sizing: border-box; overflow: hidden;
			UL.categories {
				flex: 0 0 auto; margin: 0; box-sizing: border-box; text-align: center;
				overflow: hidden; overflow-x: auto; -ms-overflow-style: auto; -webkit-overflow-scrolling: touch; scroll-behavior: auto; padding: 15px 0; white-space: nowrap;
				> LI { width: 100px; text-align: center; font-size: 11px; line-height: 14px; box-sizing: border-box; padding: 0 10px; display: inline-block; vertical-align: top; }
				A {
					color: $color-gray-dark; display: block; white-space: normal;
					.image { display: block; padding: 55% 0 0 0; background-size: contain; background-repeat: no-repeat; background-position: 50% 50%; margin-bottom: 5px; }
					.label { display: block; }
					.cat-label { display: block; margin: 0; vertical-align: 0; }
					&:hover { text-decoration: none; color: $color-link; }
				}
			}
		}
	}

	&__body {
		flex: 1 0 auto; position: relative; z-index: 0; display: flex; flex-direction: column; @include defaultPadding;
		&__limiter { width: 100%; max-width: $content-maxwidth; margin-left: auto; margin-right: auto; flex: 0 1 auto; }

		&.gray { background-color: $color-gray-lightest; }
	}

	&__footer {
		flex: 0 0 auto; @include defaultPadding; box-sizing: border-box; background-color: $color-gray-dark; color: $color-gray-medium; font-size: 12px; margin-top: 12px;

		H1, H2, H3, H4, H5, H6 { color: $color-white; font-size: inherit; font-weight: $bold; @include defaultMargin(); }
		A {
			color: inherit;
			&:hover { color: $color-white; }
		}

		.column { overflow: hidden; position: relative; }

		@include mobile {
			padding: $gridunit;
		}
		@include not-mobile {
			display: flex; flex-direction: row; flex-wrap: nowrap;
			.column { flex: 1 1 25%; padding-right: $gridunit; box-sizing: border-box; }
			H3 { font-size: $font-size-large; font-weight: 300; }
			H4 { font-size: $font-size-medium; font-weight: 300; }
			H5, H6 { font-size: inherit; font-weight: 400; }
			P { margin: 10px 0 0 0; }
		}


		&__limiter {
			width: 100%; max-width: $content-maxwidth; margin: auto;
			@include not-mobile { display: flex; flex-direction: row; flex-wrap: nowrap; align-items: flex-start; justify-content: stretch; }
		}

		.group {
			INPUT[type="checkbox"] { display: none; }
			@include mobile {
				LABEL {
					display: block; border-top: 1px solid rgba($color-white, 0.1); background-color: $color-gray-dark; z-index: 2; cursor: pointer; position: relative;
					margin: -$gridunit 0 0 0; padding: $gridunit / 2 0;
					&:before { content: "+"; float: right; transition: all $uispeed $uieasing 0ms; font-weight: 400; }
				}
				INPUT[type="checkbox"] {
					~ .group-content { transform: translateY(-100px); pointer-events: none; position: absolute; left: 0; top: 100%; right: 0; width: auto; z-index: -1; margin: 0; padding: 0 0 20px 20px; }
					&:checked {
						~ .group-content { transform: none; pointer-events: auto; position: static; z-index: 0; transition: all $uispeed $uieasing 0ms; }
						~ * LABEL:before { transform: rotate(45deg); }
					}
				}
			}
		}

		UL.nav {
			padding: 0; margin: 15px 0 0 0;
			> LI { margin: 5px 0 0 0; }
		}

		&__social {
			display: flex; flex-direction: row; flex-wrap: wrap;
			> LI {
				margin: $gridunit $gridunit 0 0;
				A {
					display: flex; align-items: center; justify-content: center; width: 36px; height: 36px; border-radius: 50%; background-color: rgba($color-white, 0.8);
					&:hover { background-color: $color-white; }
				}
			}
			IMG { width: 16px; height: 16px; }
		}

		&__newsletter {
			.entry {
				$h: 40px;
				@include defaultMargin();
				LABEL {
					display: block; line-height: inherit; font-size: inherit; margin: 0 0 5px 0;
					&:before { display: none; }
				}
				.field {
					display: flex; flex-direction: row; flex-wrap: nowrap; background-color: $color-white; border-radius: $base-radius; overflow: hidden;
					INPUT { flex: 1 1 auto; background-color: transparent; border: none; width: 100%; box-sizing: border-box; padding: 0 10px; box-shadow: none; color: inherit; font-size: 16px; line-height: $h; height: $h; outline: none; }
					BUTTON { flex: 0 0 auto; display: block; padding: 0 15px; margin: 4px; background-color: $color-gray-lightest; border: none; outline: none; line-height: $h - 8; height: $h - 8; font-weight: $bold; font-size: 12px; border-radius: $base-radius; cursor: pointer; }
				}
			}
		}
	}

	&__pre-cart {
		flex: 0 0 auto; @include defaultPadding; box-sizing: border-box; background-color: $color-white;

		&__limiter {
			width: 100%; max-width: $content-maxwidth; margin: auto;
			@include mobile { text-align: center; }
			@include not-mobile { display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; }
		}

		&__image {
			position: relative; @include defaultMargin();
			@include not-mobile { margin-right: $gridunit; flex: 0 0 110px; }
			DIV {
				padding: 66% 0 0 0; background-repeat: no-repeat; background-position: 50% 50%; background-size: contain;  position: relative;
				IMG { position: absolute; left: 0; top: 0; width: 100%; height: 100%; object-fit: contain; }
				@include mobile { height: 100px; padding: 0; }
				IMG { position: absolute; left: 0; top: 0; width: 100%; height: 100%; object-fit: contain; }
			}
		}

		&__product {
			flex: 1 1 auto; overflow: hidden; text-overflow: ellipsis; @include defaultMargin();
		}

		&__title {
			font-weight: $bold; font-size: $font-size-medium;
		}

		&__message {
			color: $color-success;
			&.text-intent-danger { color: $color-danger; }
		}

		&__actions {
			@include defaultMargin();
			@include not-mobile { flex: 0 0 150px; margin-left: $gridunit; }
			.cart-buttons {
				margin: 0;
				> LI:first-child { margin-top: 0; }
			}
		}
	}

	&__copyright {
		flex: 0 0 auto; @include defaultPadding; box-sizing: border-box;
		background-color: $color-black; color: $color-white; font-size: 13px;

		H1, H2, H3, H4, H5, H6 { color: $color-white; }
		A { color: inherit; }

		.logo {
			flex: 0 0 auto;
			IMG { width: 150px; height: auto; }
		}

		&__limiter {
			width: 100%; max-width: $content-maxwidth; margin: auto;
			@include not-mobile { display: flex; flex-direction: row; flex-wrap: nowrap; align-items: flex-start; justify-content: space-between; }
			@include mobile { text-align: center; }
		}

	}

	&__illustration { background: 50% 50% no-repeat; background-size: cover; background-color: $color-gray-lightest; padding-top: 40%; margin-top: 50px; }

	&__page-header {
		flex: 1 0 auto; position: relative; z-index: 0; display: flex; flex-direction: column; @include defaultPadding; padding-top: $gridunit / 2 !important;
		&__limiter { width: 100%; max-width: $content-maxwidth; margin-left: auto; margin-right: auto; flex: 0 1 auto; }

		H1 {
			margin-top: 30px;
			@include mobile { margin-top: 10px; }
		}
		&.has-tabs { padding-bottom: 0; border-bottom: none; }

		.breadcrumb {
			font-size: $font-size-smaller; line-height: 1.4; display: flex; flex-direction: row; justify-content: space-between; color: $color-gray-medium; white-space: nowrap;
			.back {
				margin: 5px 0 0 0; position: relative;
				&:after { content: ""; position: absolute; right: 100%; top: 50%; width: 0.5em; height: 0.5em; box-sizing: border-box; border: solid currentColor; border-width: 0.15em 0.15em 0 0; transform: translate(-150%, -50%) scaleX(0.85) rotate(-135deg) scale(0.8); transform-origin: 50% 50%; }
				@include mobile { display: none; }
			}
			.path {
				display: flex; flex-direction: row;
				> LI {
					margin: 5px 10px 0 0;
					&:not(:last-child) {
						A { margin-right: 10px; }
						&:after { content: "/"; opacity: 0.6; }
					}
				}
			}
			A {
				color: inherit; text-decoration: none;
				&:hover { color: $color-link-hover; }
			}
			@include mobile {
				font-size: $font-size-smallest;
				.path > LI {
					margin-right: 5px;
					&:not(:last-child) {
						A { margin-right: 5px; }
					}
				}
			}
		}
	}

	&__product-header {
		display: flex; flex-direction: column; flex: 0 0 auto; box-sizing: border-box; position: sticky; z-index: 8; top: 105px;
		padding: 0 $spacer-desktop; backdrop-filter: saturate(180%) blur(20px);
		background-color: rgba($color-white, 0.72); color: $color-text-dark;
		&.dark { background-color: rgba(29, 29, 31, 0.72); color: $color-white; border-bottom: 1px solid rgba($color-white, 0.1); }

		@include mobile { top: 50px; padding: 0 $spacer-mobile; }
		@include tablet { top: 76px; padding: 0 $spacer-tablet; }

		&__limiter {
			width: 100%; max-width: $content-maxwidth; margin: auto; box-sizing: border-box; position: relative; padding: 15px 0;
			display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; justify-content: space-between;
		}
		&__nav {
			position: absolute; left: 50%; transform: translate(-50%,0); opacity: 0.8;
			UL {
				display: flex; margin: 0; padding: 0;
				LI {
					padding-left: 0; margin: 0 $gridunit-large 0 0;
					&:before { display: none; }
					A {
						color: inherit;
						&:hover,
						&:active,
						&.active { text-decoration: underline; }
					}
				}
			}
		}
		&__actions { flex: 0 0 auto; display: flex; }

		&__button {
			cursor: pointer; display: block; text-align: center; white-space: nowrap; margin-left: $gridunit; flex: 0 0 auto;
			background: $color-link; color: $color-white; font-size: 12px;
			font-weight: 400; padding: 0 11px; border-radius: 12px; line-height: 24px;
			&:hover { background-color: $color-link-hover; text-decoration: none; }

			&[disabled],
			&.disabled { opacity: 0.5; pointer-events: none; }
		}
		@include not-desktop {
			&__limiter { flex-direction: column; }
			&__nav { position: static; transform: none; padding: $gridunit 0; }
		}

		H1 { font-size: 20px; margin: 0; }
	}

	@include mobile {
		&__pre-header .search { display: none; }
	}
}

.global-frame-valgeklaar {
	padding: 0; margin: 0; background-color: $color-white;
	BODY { padding: 0; margin: 0; }
}

// Overlay placement

.global-frame-valgeklaar {
	@include not-desktop {
		.#{$ns}-overlay .overlayholder,
		.#{$ns}-overlay .overlayholder-backdrop { top: 0; }
	}
	@include desktop {
		.#{$ns}-overlay .overlayholder,
		.#{$ns}-overlay .overlayholder-backdrop { left: 0; }
		.#{$ns}-overlay .overlay-focusview-holder { padding-left: 0; }
	}
}

// Searchfield

.search {
	box-shadow: none; flex: 0 1 250px; display: block; position: relative;
	LABEL {
		display: flex; flex-direction: row; flex-wrap: nowrap; flex: 1 1 auto; align-items: center; padding-left: 9px; position: relative;
		border-radius: 15px; background-color: rgba($color-white, 0.2); overflow: hidden;
	}
	.label { display: none; }
	INPUT:not([type="submit"]) {
		order: 2;
		padding: 0 0 0 10px; border: 0 none; outline: none; box-shadow: none; width: 100%; overflow: hidden; border-radius: 0; flex: 1 1 auto; box-sizing: border-box; background-color: transparent;
		color: currentColor; height: 30px; line-height: 30px; font-size: 14px;
		&::placeholder { color: rgba($color-white, 0.5); }
	}
	BUTTON,
	INPUT[type="submit"] {
		order: 3;
		padding: 0 15px; margin: 3px; line-height: 24px; height: 24px; flex: 0 0 auto; border: none; box-shadow: none; background-color: rgba($color-black, 0.5); color: inherit; font-size: 12px !important; border-radius: 12px; cursor: pointer;
	}
	@include mobile {
		border-radius: 20px;
		INPUT:not([type="submit"]) { font-size: 16px; height: 40px; line-height: 40px; margin: -5px 0; }
		BUTTON,
		INPUT[type="submit"] { line-height: 30px; height: 30px; border-radius: 15px; background-color: rgba($color-white, 0.2); margin: 0 15px; }
	}

	.icon {
		flex: 0 0 auto; display: block; width: 14px; height: 14px; overflow: hidden; margin: 0;
		img { width: 100%; height: auto; float: left; }
		@include mobile { margin: 0; }
	}

	SPAN.icon { order: 1; }
	BUTTON.icon { order: 4; display: none; }

	&__results {
		&__list {
			flex: 0 1 100%; position: relative;
			padding: 0; transition: all $uispeed $uieasing 0ms;
			@include desktop {
				transform: translate(0, -20px); @include overflow();
			}
			> LI {
				&:not(:last-child) { border-bottom: 1px solid $color-gray-lighter; }
			}
		}
		&__result {
			A {
				display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; justify-content: flex-start; text-decoration: none; overflow: hidden; white-space: nowrap; padding: 10px; position: relative; line-height: 18px;
				&.active,
				&:hover {
					text-decoration: none;
					.name { color: $color-link; }
				}
				&.active { background-color: rgba($color-link, 0.05); }
			}
			.image {
				flex: 0 0 40px; width: 40px; height: 40px; margin-right: 10px; overflow: hidden; background-size: contain; background-repeat: no-repeat; background-position: 50% 50%;
				IMG { width: 100%; height: 100%; object-fit: contain; }
			}
			.text {
				flex: 1 1 auto; overflow: hidden; text-overflow: ellipsis;
			}
			.name { font-weight: $medium; flex: 1 1 auto; overflow: hidden; text-overflow: ellipsis; }
			.price {
				color: $color-gray-dark;
				SMALL { color: $color-gray-medium; }
			}
		}
	}
}

.search-mobile {
	& &-heading {
		background-color: $color-black; color: $color-white;
		.search {
			flex: 1 0 auto; display: flex; flex-direction: column; justify-content: center; color: $color-white;
			INPUT:not([type="submit"]) {
				color: inherit; font-size: 16px;
				&::placeholder { color: rgba($color-white, 0.5); }
			}
			BUTTON.icon {
				display: block; padding: 0; margin: 0; width: 16px; height: 16px; border-radius: 0; background-color: transparent; opacity: 0.5; position: relative; overflow: visible;
				&:hover { opacity: 1; }
				&:before { content: ""; position: absolute; left: -15px; top: -15px; right: -15px; bottom: -15px; }
			}
			LABEL { border-radius: 0; background-color: transparent; padding: 10px 20px; }
		}
	}
	& &-content {
		padding: 0;
	}
}

.overlay-search {
	&-content {
		background-color: $color-white;
	}
}

// Site menu

.site-menu {
	flex: 0 0 auto; display: flex;

	@include not-desktop { flex-direction: row; align-items: center; }

	.menutoggler {
		font-size: 0; color: rgba($color-black, 0); padding: 10px; margin-right: 10px; box-sizing: border-box; position: relative; opacity: 0.5;
		@include desktop { display: none; }
		@include tablet { margin-right: 20px; }
		span {
			display: block; width: 20px; height: 2px; background-color: $color-black; position: relative;
			&:before, &:after { content: ""; height: 2px; position: absolute; left: 0; right: 0; background-color: $color-black; }
			&:before { top: -6px; }
			&:after { bottom: -6px; }
		}
		&:hover {
			opacity: 1;
		}
	}
	NAV, NAV > UL, NAV > UL > LI { flex: 1 0 auto; display: flex; flex-direction: row; flex-wrap: nowrap; }
	NAV {
		font-weight: 300; font-size: 15px;
		@include not-desktop { display: none !important; }
		@include desktop-wide { font-size: 17px; }
		UL { padding: 0; margin: 0; }
		LI {
			padding: 0; margin: 0 !important;
			&:before { display: none; }
		}
		> UL {
			list-style: none;
			> LI {
				padding: 0 20px 0 0;
				&:last-child { padding-right: 0; }
				> A { display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; justify-content: center; }
			}
			A {
				display: block; padding: 10px 0; position: relative; color: $color-black;
				.label {}
				.dropper,
				.dropper span {
					position: absolute; left: 0; right: 0; top: 0; bottom: 0; z-index: 1; font-size: 0; color: rgba($color-black, 0);
				}
				.#{$ns}-popover-target {
					&:before { content: ""; position: absolute; left: 0; right: 0; bottom: 0; height: 2px; background-color: $color-link; opacity: 0; }
					&.#{$ns}-popover-open:before { opacity: 1; }
				}
				&:hover {
					color: $color-link; text-decoration: none;
					.#{$ns}-popover-target {
						&:before { opacity: 1; }
					}
				}
			}
			IMG { width: 100px; }
			UL { display: none; }
		}
	}

	.#{$ns}-popover-target { display: block; position: relative; }

	&-portal {
		.#{$ns}-transition-container { z-index: $modalZ + $pt-z-index-overlay; }
	}

	&-portal {
		.#{$ns}-transition-container { z-index: $modalZ + $pt-z-index-overlay; }
	}

	&-popover {
		display: block; z-index: $modalZ + $pt-z-index-overlay; overflow: hidden;

		.#{$ns}-popover-content {
			max-width: 100%; padding: 0;
			@include clearfix;
			@include mobile { max-width: calc(100vw - 40px); }
			&:focus { outline: none; }
			*:focus { outline: none; }
		}
		.#{$ns}-transition-container {
			display: flex; z-index: $modalZ + $pt-z-index-overlay; padding-bottom: $gridunit / 2;
			&:focus { outline: none; }
			&.#{$ns}-popover-leave .#{$ns}-popover-content { pointer-events: none; }
			&[data-x-out-of-boundaries] { display: none; }
		}

		&:focus { outline: none; }

		.#{$ns}-overlay-inline { display: inline; overflow: visible; }
		.#{$ns}-popover {
			&-target { position: relative; display: block; }
		}
		.#{$ns}-submenu {
			.#{$ns}-popover {
				padding-top: 5px;
			}
		}

		.#{$ns}-menu {
			background-color: rgba($color-gray-lightest, 0.97); border: solid $color-gray-light; border-width: 0 1px 1px 1px; border-radius: 0; font-size: 13px;
			> LI { border-top: 1px solid $color-gray-light; min-width: 200px; }
			&-item {
				background-color: transparent; padding: 15px 20px; cursor: pointer; font-weight: 400;
				&:hover { box-shadow: inset 0 -2px 0 0 $color-link; text-decoration: none; background-color: transparent; }
				&.important { font-weight: $bold; color: $color-link; }
			}
		}
	}

	&-mobile {
		& &-heading {
			&.right { flex-direction: row-reverse; }
			background-color: $color-black; color: $color-white; display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: space-between; padding: $gridunit;
			BUTTON {
				cursor: pointer; border: none; background-color: transparent; padding: 0; width: $gridunit; height: $gridunit; position: relative; color: currentColor;
				svg { position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); }
				path { fill: currentColor; }
			}
			.logo { width: auto; height: $mobile-header-height - $gridunit; margin: $gridunit / 2 $gridunit; }
		}
		& &-content {
			padding: 0 20px 20px 20px;
			UL {
				padding: 1px 0 0 0; margin: 0; list-style: none; position: relative; box-sizing: border-box; min-width: 0;
				&:before { content: ""; width: auto; height: 1px; opacity: 0.15; background-color: currentColor; position: absolute; left: 0; right: 0; bottom: auto; top: 0; transform: none; }
				LI {
					padding: 0 0 1px 0; margin: 0; position: relative;
					&:before { content: ""; width: auto; height: 1px; opacity: 0.15; background-color: currentColor; position: absolute; left: 0; right: 0; bottom: 0; top: auto; transform: none; }
				}
				A { display: block; padding: 10px 0; color: inherit; text-decoration: none; }
				UL {
					padding: 0 0 0 20px;
					> LI:last-child {
						padding: 0;
						&:before { display: none; }
					}
				}
			}
		}
	}
}

.overlay-mainmenu {
	&-content { background-color: $color-black; color: $color-white; }
}

// Category tree

.category-tree {
	flex: 0 0 auto; margin-top: $gridunit;

	.menutoggler {
		display: none; margin: $gridunit * -1 $gridunit * -1 0 $gridunit * -1; padding: $gridunit / 2 $gridunit; color: inherit; background-color: $color-gray-lightest; border: solid $color-gray-lighter; border-width: 1px 0;
		@include mobile { display: flex; align-items: center; justify-content: flex-start; }

		.icon {
			flex: 0 0 auto; display: block; width: 12px; height: 12px; overflow: hidden; margin: 0 7px 0 0; opacity: 0.3;
			IMG { width: 100%; height: auto; float: left; transform: translate(0, -50%); }
		}
		.label {
			flex: 1 1 auto;
		}
	}

	&__group {
		overflow: hidden; position: relative; user-select: none; line-height: $gridunit; background-color: $color-gray-lightest;
		& + & { margin-top: 5px; }
		@include mobile {
			display: none;
			& + & { margin-top: 1px; }
		}

		> INPUT[type="checkbox"] {
			position: absolute; left: -10000px;
			&:focus + LABEL {
				box-shadow: $shadow-focus-inset;
			}
		}

		> LABEL {
			display: block; background-color: $color-gray-lightest; z-index: 2; cursor: pointer; position: relative; font-weight: $bold; box-sizing: border-box; line-height: inherit;
			margin: 0; padding: $gridunit / 2 0 $gridunit / 2 $gridunit;
			&:before { content: "+"; transition: all $uispeed $uieasing 0ms; font-weight: 400; display: block; width: 20px; text-align: center; line-height: 20px; position: absolute; right: 5px; top: 50%; transform: translate(0, -50%); transform-origin: 50% 50%; z-index: 2; }
			&:after { content: ""; position: absolute; right: 5px; top: 50%; height: 20px; width: 20px; border: 1px solid rgba($color-black, 0.1); box-sizing: border-box; background-color: $color-white; z-index: 1; transform: translate(0, -50%); }
			&:hover { color: $color-link; }
		}

		UL { display: none; }

		> INPUT[type="checkbox"] + * + * { transform: translateY(-100px); visibility: hidden; pointer-events: none; position: absolute; left: 0; top: 100%; width: 100%; z-index: -1; margin: 0; transition: all $uispeed $uieasing 0ms; display: block; }
		> INPUT[type="checkbox"]:checked ~ LABEL:before,
		> INPUT[type="checkbox"]:checked + * LABEL:before { transform: translate(0, -50%) rotate(45deg); }
		> INPUT[type="checkbox"]:checked + LABEL ~ *,
		> INPUT[type="checkbox"]:checked + * ~ *, { display: block; visibility: visible; transform: none; pointer-events: auto; position: static; z-index: 0; }

		A {
			display: block; color: inherit; padding: $gridunit / 4 $gridunit;
			&:hover { text-decoration: none; color: $color-link; }
			&.active {
				color: $color-link;
				+ UL { display: block; }
			}
			&:focus { box-shadow: $shadow-focus; outline: none; }
			.count { opacity: 0.3; }
			@include mobile {
				padding: $gridunit / 2 0;
			}
		}

		.item-row {
			display: flex; flex-direction: row; flex-wrap: nowrap; background-color: $color-gray-lightest; position: relative; z-index: 1;
			A { flex: 1 1 auto; padding: $gridunit / 4 $gridunit; display: block; }
			LABEL {
				flex: 0 0 auto; padding: $gridunit / 4 0;
				width: 30px; text-align: center;
			}
			@include mobile {
				A, LABEL { padding: $gridunit / 2 0; }
			}
		}

		UL:not([class]) {
			padding: 0; margin: 0; box-sizing: border-box; overflow: hidden;
			LI {
				padding: 0; margin: 0; overflow: hidden; border-top: 1px solid $color-white;
				&:before { display: none; }
				LABEL { font-weight: 400; }
				@include mobile {
					+ LI { border-top: 1px solid $color-white; }
				}
			}
			LI LI {
				font-size: $font-size-small;
				LABEL:before { opacity: 0.5; }
			}
			LI LI LI { font-size: $font-size-smaller; }

			LI A { padding-left: $gridunit; }
			LI LI A { padding-left: $gridunit * 1.5; }
			LI LI LI A { padding-left: $gridunit * 2; }
			LI LI LI LI A { padding-left: $gridunit * 2.5; }
			LI LI LI LI LI A { padding-left: $gridunit * 3; }
			LI LI LI LI LI LI A { padding-left: $gridunit * 3.5; }

			LI LABEL {
				display: block; padding: $gridunit / 2 $gridunit;
				INPUT[type="checkbox"] { margin-right: $gridunit-small; }
				SPAN.count { opacity: .5; }
			}
		}
	}

	&__mobile {
		& &__heading {
			&.right { flex-direction: row-reverse; }
			background-color: $color-white; color: $color-text-dark; display: flex; flex-direction: column; flex-wrap: nowrap; justify-content: space-between; padding: $gridunit;
			BUTTON:not([class]) {
				cursor: pointer; border: none; background-color: transparent; padding: 0; width: $gridunit; height: $gridunit; position: relative; color: currentColor;
				svg { position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); }
				path { fill: currentColor; }
			}
			.logo { width: auto; height: $mobile-header-height - $gridunit; margin: $gridunit / 2 $gridunit; }
		}
		& &__content {
			// padding: 0 20px 20px 20px;
		}
	}

	&__tags {
		&__list {
			border-bottom: 1px solid $color-gray-light;
			margin: $gridunit - 2px 0 0 0; padding: 0 0 $gridunit 0;
			display: flex; flex-direction: row; flex-wrap: wrap;
			> LI { margin: 2px 2px 0 0; flex: 0 0 auto; }
		}

		+ .product-toolbar {
			margin-top: 5px;
		}

		&__tag {
			$size: 26px;
			background-color: $color-gray-lightest; border-radius: $size / 2; font-size: $font-size-smaller; padding: 0; margin: 0; color: currentColor;
			line-height: $size; border: none; appearance: none;
			&:hover {
				cursor: pointer; text-decoration: none; background-color: $color01; color: $color-white;
			}

			.wrapper { display: flex; flex-direction: row; flex-wrap: nowrap; }
			.label { flex: 0 1 auto; display: block; line-height: $size; white-space: nowrap; text-overflow: ellipsis; max-width: 200px; padding: 0 12px; }
			.icon { flex: 0 0 auto; margin: 0 0 0 -12px; width: $size; height: $size; border-radius: 50%; position: relative; color: currentColor; }
			svg { position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); }
			path { fill: currentColor; }
		}
		.reset &__tag {
			background-color: transparent; color: $color-link; box-shadow: inset 0 0 0 1px $color-gray-light;
			&:hover { background-color: $color01; color: $color-white; box-shadow: none; }
		}
	}
}

.overlay-category-tree {
	&-content {
		background-color: $color-white; color: $color-text-dark;
		.category-tree {
			margin: 0;
			.menutoggler { display: none; }
			&__group { display: block; }
		}
	}
}

.page-header__tabs {
	display: flex; margin: $gridunit 0 0; position: relative;
	&:before { content: ""; position: absolute; left: 50%; bottom: 0; width: 100vw; border-bottom: 1px solid $color-gray-lighter; transform: translate(-50%, 0); }
	INPUT[type="radio"] {
		position: absolute; left: -10000px;
		&:checked + LABEL {
			background-color: $color-gray-lighter; color: $color-black;
			&:before { border-color: $color02; }
			&:after { background-color: $color02;}
		}
		&:disabled + LABEL { cursor: default; }
	}
	LABEL {
		display: block; position: relative; padding: $gridunit $gridunit-large $gridunit $gridunit-large+$gridunit*2; color: $color-gray-medium-dark; font-weight: $bold; cursor: pointer;
		&:before,
		&:after {
			content: ""; position: absolute; left: $gridunit; top: 50%; transform: translate(0, -50%);
		}
		&:before { width: 24px; height: 24px; border: 1px solid $color-gray-medium-light; box-sizing: border-box; border-radius: 50%; background-color: $color-white; }
		&:after { width: 12px; height: 12px; margin: 0 6px; border-radius: 50%; }
	}
}
